@import "config/grid.yaml";
@import "config/templates.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "../init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/singles/single";

.single-post {
  --bs-body-color: #{$gray-900};
}
